import { usePopupStore, AlertType, ModalData } from './PopupStore';
import { ReactElement } from 'react';

export const DisplayErrorAlert = (message: string, title?: string, networkStatus?: number) =>
  usePopupStore.getState().displayAlert('error', message, title, networkStatus);

export const DisplayInfoAlert = (message: string, title?: string, networkStatus?: number) =>
  usePopupStore.getState().displayAlert('info', message, title, networkStatus);

export const DisplayWarningAlert = (message: string, title?: string, networkStatus?: number) =>
  usePopupStore.getState().displayAlert('warning', message, title, networkStatus);

export const DisplaySuccessAlert = (message: string, title?: string, networkStatus?: number) =>
  usePopupStore.getState().displayAlert('success', message, title, networkStatus);

export const DisplayAlert = (type: AlertType, message: string, title?: string, networkStatus?: number) =>
  usePopupStore.getState().displayAlert(type, message, title, networkStatus);

export const DisplayModal = ({
  content = <></>,
  onCancel = undefined,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  title = '',
  isConfirmDisabled = false
}: ModalData) => usePopupStore.getState().displayModal(content, onCancel, onConfirm, confirmText, cancelText, title, isConfirmDisabled);
