import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { FormProvider, useForm, useWatch, useFormContext, UseFormReturn, FieldValues, DefaultValues, Path } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@ShadcnComponents/ui/button";
import { z, ZodTypeAny } from "zod";
import PrimaryButton from "@MSMComponents/Buttons/PrimaryButton";

// Extend the UseFormReturn type to include the custom function
// Extend UseFormReturn to include custom function
interface MSMUseFormReturn<T extends FieldValues> extends UseFormReturn<T> {
    focusNextField: () => void;
}

// Create a custom hook for the form context
export const useMSMFormContext = <
    TFieldValues extends FieldValues = FieldValues
>(): MSMUseFormReturn<TFieldValues> => {
    const context = useFormContext<TFieldValues>();

    if (!("focusNextField" in context)) {
        throw new Error("useMSMFormContext must be used within an MSMForm.");
    }

    return context as MSMUseFormReturn<TFieldValues>;
};

interface MSMFormProps<T extends ZodTypeAny> {
    schema?: T; // Zod schema for validation
    children: React.ReactNode;
    onSubmit?: (data: z.infer<T>) => void;
    onReset?: () => void;
    persistOnReset?: Path<z.infer<T>>[];
    autoFocusField?: Path<z.infer<T>>;
    submitButtonText?: string;
    hasClearButton?: boolean;
    centerSubmitButton?: boolean;
    isAuto?: boolean;
    row?: boolean;
    clearOnSubmit?: boolean;
    hideSubmit?: boolean;
    shouldSelectOnFocus?: boolean;
    defaultValues?: Partial<z.infer<T>>;
}

export interface MSMFormRef {
    submit: () => Promise<boolean>;
    resetForm: () => void;
    isSubmitDisabled: boolean;
}

const MSMForm = forwardRef(
    <T extends ZodTypeAny>(
        {
            schema,
            children,
            onSubmit,
            onReset,
            persistOnReset = [],
            autoFocusField,
            submitButtonText = "Submit",
            hasClearButton = false,
            centerSubmitButton = false,
            isAuto = false,
            row = false,
            clearOnSubmit = false,
            hideSubmit = false,
            shouldSelectOnFocus = false,
            defaultValues = {},
        }: MSMFormProps<T>,
        ref: React.Ref<MSMFormRef>
    ) => {
        type FormData = z.infer<T>;

        /* Records the fieldOrder from the schema */
        const fieldOrder = useMemo(
            () =>
                schema instanceof z.ZodObject
                    ? Object.keys(schema.shape) as Path<FormData>[]
                    : [],
            [schema]
        );

        /* Initialize form */
        const form = useForm<FormData>({
            resolver: zodResolver(schema ?? z.object({})),
            defaultValues: defaultValues as DefaultValues<FormData>,
        });

        /* Allows us to setFocus, Reset, and Watch form valeus*/
        const { setFocus, reset } = form;
        const values = useWatch<FormData>({ control: form.control });

        /* Returns if all fields are filled or not */
        const areAllFieldsFilled = useMemo(
            () =>
                fieldOrder.every((field) => {
                    const fieldValue = values[field];
                    return fieldValue !== undefined && fieldValue !== null && fieldValue !== "";
                }),
            [fieldOrder, values]
        );

        /* Sets focus in a reliable manner */
        const setFocusWithDelay = (name: Path<FormData>, options?: { shouldSelect: boolean }) => {
            setTimeout(() => {
                setFocus(name, options);
            }, 100);
        };

        /* Function passed to fields to allow the form to set focus */
        const focusNextField = () => {
            if (isAuto) {
                const nextField = fieldOrder.find((field) => values[field] === undefined);
                console.log(nextField)
                if (nextField) {
                    setFocusWithDelay(nextField, { shouldSelect: shouldSelectOnFocus });
                }
            }
        };

        /* 
            AutoFocuses fields on reset, 
            persists data as determined persistOnReset prop,
            calls onReset()
         */
        const handleReset = () => {
            if (autoFocusField && fieldOrder.includes(autoFocusField)) {
                setFocusWithDelay(autoFocusField);
            } else if (isAuto && fieldOrder.length > 0) {
                setFocusWithDelay(fieldOrder[0]);
            }

            const resetData: Partial<FormData> = fieldOrder.reduce((acc, field) => {
                if (persistOnReset.includes(field)) {
                    acc[field] = values[field];
                } else {
                    acc[field] = undefined;
                }
                return acc;
            }, {} as Partial<FormData>);

            reset(resetData);
            onReset?.();
        };

        const onFormSubmit = (data: FormData) => {
            onSubmit?.(data);
            if (clearOnSubmit) {
                handleReset();
            }
        };

        /* Passes focusNextField to MSMFormField */
        const MSMFormContext: MSMUseFormReturn<FormData> = {
            ...form,
            focusNextField,
        };

        /* Allows for forward referencing for cases like modal usage */
        useImperativeHandle(ref, () => ({
            submit: async (): Promise<boolean> => {
                let isSuccessful = false;

                await form.handleSubmit(
                    async (data) => {
                        try {
                            await onFormSubmit(data);
                            isSuccessful = true;
                        } catch (error) {
                            console.error("Form submission failed:", error);
                            isSuccessful = false;
                        }
                    },
                    (errors) => {
                        console.error("Validation errors:", errors);
                        isSuccessful = false;
                    }
                )();

                return isSuccessful;
            },
            resetForm: handleReset,
            isSubmitDisabled: !areAllFieldsFilled,
        }));

        /* On load, set focus to field if it exists.*/
        useEffect(() => {
            if (autoFocusField && fieldOrder.includes(autoFocusField)) {
                setFocus(autoFocusField);
            }
        }, []);

        return (
            <FormProvider {...MSMFormContext}>
                <form
                    onSubmit={form.handleSubmit(onFormSubmit)}
                    className={`${row ? "flex flex-row items-end gap-4" : ""}`}
                >
                    <div className={row ? "flex flex-row gap-4 flex-grow" : ""}>
                        {children}
                    </div>
                    <div>
                        {!hideSubmit && onSubmit && (
                            <PrimaryButton
                                onClick={() => form.handleSubmit(onFormSubmit)}
                                text={submitButtonText}
                                disabled={!areAllFieldsFilled}
                                className={`${centerSubmitButton ? "break-words whitespace-normal p-4 self-center w-full h-auto" : "mt-4"}`} />
                        )}
                        {hasClearButton && (
                            <Button type="button" variant="outline" onClick={handleReset}>
                                Clear
                            </Button>
                        )}
                    </div>
                </form>
            </FormProvider>
        );
    }
);

MSMForm.displayName = "MSMForm";

export default MSMForm;
