

import { z } from "zod";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { HttpStatusCode } from "axios";

import { CPCStatus, FeeType } from "@typings/Enums";
import { CheckoutSubmit } from "@typings/DataModels/Checkout";
import { MarketVendor, MarketFee, MarketToken, Vendor } from "@typings/DataModels/Common";

import { DisplayErrorAlert, DisplayAlert, DisplaySuccessAlert } from "@MSMComponents/Popups/PopupHelpers";
import { isEmptyList, isProducer, roundMoney, toISOStringForSending, toReadableDate } from "../../Utilities";
import DescribeText from "@MSMComponents/DataDisplay/DescribeText";
import { APIResult, APIResultState, callEndpoint, callEndpointWithState, DefaultApiResult } from "../../lib/APICaller";
import MSMDropdown, { convertToDropdownItems } from "@MSMComponents/Inputs/MSMDropdown";
import MSMForm from "@MSMComponents/Form Flow/MSMForm";
import MSMFormField from "@MSMComponents/Form Flow/MSMFormField";
import MSMFlexGrid from "@MSMComponents/Layout/MSMFlexGrid";
import MSMNumericalInput from "@MSMComponents/Inputs/MSMNumericalInput";
import MSMPage from "@MSMComponents/Layout/MSMPage";
import MSMMoneyDisplay from "@MSMComponents/DataDisplay/MSMMoneyDisplay";
import MSMHorizontalDivideLine from "@MSMComponents/Layout/MSMHorizontalDivideLine";
import { MSMVendorBadge } from "@MSMComponents/DataDisplay/MSMVendorBadge";
import { BannerAlert } from "@MSMComponents/Popups/BannerAlert";
import { DisplayModal } from "@MSMComponents/Popups/PopupHelpers";
import APIResultDisplay from "@MSMComponents/APIResultDisplay";
import {
  createTokenFieldModel,
  generateCPCBannerText,
  GenerateTokensForSubmission,
  getMarketFeeCalculationString,
  getTokenLabel
} from "./CheckoutHelpers";
import { SubmitCheckout, GetCheckoutData } from "./CheckoutAPICalls";



//Checkout Config that won't change throughout the checkout
interface CheckoutConfig {
  vendors: MarketVendor[]
  fees: MarketFee[]
}

//To keep track of Token Fields
export interface TokenFieldModel {
  quantity: number;
  token: MarketToken;
}

const Checkout = () => {

  // State Variables
  const [checkoutConfig, setCheckoutConfig] = useState<APIResult<CheckoutConfig>>(DefaultApiResult);
  const [Tokens, setTokens] = useState<TokenFieldModel[]>([]);
  const [grossProfit, setGrossProfit] = useState<number | undefined>(undefined)
  const [selectedVendor, setSelectedVendor] = useState<Vendor>();
  const [moneyOwed, setMoneyOwed] = useState<number>(0);
  const [marketFee, setMarketFee] = useState<number>(0);
  const [govFee, setGovFee] = useState<number>(0);
  const [selectedMarketFee, setSelectedMarketFee] = useState<MarketFee | null>(null);

  //CPC Banner State
  const [vendorCPCStatus, setCPCStatus] = useState<CPCStatus>(CPCStatus.UP_TO_DATE);    //Setting ot UP_TO_DATE avoids visual jarring.
  const [CPCBannerText, setCPCBannerText] = useState<string>("");

  //Variables from Search Params
  const [searchParams] = useSearchParams();
  const marketId = searchParams.get('market')
  const marketName = searchParams.get("market_name")
  const date = new Date(searchParams.get(decodeURIComponent('date')) as string)


  ////////////////////////////////
  //       STATE HANDLERS       //
  //////////////////////////////// 

  //Called on form clear
  const resetCheckoutState = () => {
    const resetTokens = Tokens.map((token) => ({ ...token, quantity: 0 }));
    setTokens(resetTokens);
    setGrossProfit(0)
  }

  const calculateMarketFee = useCallback(() => {

    /* If we don't have a market fee, just return govFee */
    if (!selectedMarketFee) {
      return govFee;
    }

    /* Calculate vendor fee */
    let calculatedFee = 0;
    let GP = grossProfit || 0;
    switch (selectedMarketFee.fee_type) {
      case FeeType.PERCENT_GROSS: calculatedFee = GP * selectedMarketFee.percent; break;
      case FeeType.FLAT_FEE: calculatedFee = selectedMarketFee.flat; break;
      case FeeType.FLAT_PERCENT_COMBO: calculatedFee = selectedMarketFee.flat + selectedMarketFee.percent * GP; break;
      case FeeType.MAX_OF_EITHER: calculatedFee = selectedMarketFee.flat > selectedMarketFee.percent * GP ? selectedMarketFee.flat : selectedMarketFee.percent * GP; break;
    }

    /* Return vendor + gov fee */
    return calculatedFee + govFee;

  }, [selectedMarketFee, Tokens, grossProfit]);


  const handleTokensChanged = (newQuantity: number, fieldIndex: number) => {

    //First update the new token quantity
    const updatedTokens = Tokens.map((token, index) =>
      index === fieldIndex
        ? { ...token, quantity: newQuantity }
        : token
    );
    setTokens(updatedTokens);
  };

  const refreshCPCBanner = () => {
    const { status, bannerText } = generateCPCBannerText(selectedVendor)
    setCPCStatus(status);
    setCPCBannerText(bannerText)
  }

  const verifyCheckout = (data: any) => {

    //Verify the CPC if we have a, if bad, display modal
    if (isProducer(selectedVendor?.type) &&
      vendorCPCStatus === CPCStatus.PAST_DUE || vendorCPCStatus === CPCStatus.URGENT) {
      DisplayModal({
        onConfirm() { submitCheckoutToDatabase(data); return Promise.resolve(true); },
        onCancel() { return Promise.resolve(false); },
        title: "CPC Alert",
        content: <div className="text-md font-normal">Are You Sure You Want to submit with an invalid cpc?</div>,
        confirmText: "Submit"
      })
    } else {
      submitCheckoutToDatabase(data)
    }
  }

  const submitCheckoutToDatabase = (data: any) => {

    if (date) {
      // Builds the data sent to the db
      let CheckoutSubmitData: CheckoutSubmit = {
        market_vendor_id: data.vendor,
        market_date: toISOStringForSending(date),
        reported_gross: data.gross_profit,
        market_fee: marketFee,
        money_owed: roundMoney(moneyOwed),
        tokens: GenerateTokensForSubmission(Tokens),
        receipt_email: selectedVendor?.receipt_email,
        market_fee_calculation: getMarketFeeCalculationString(
          selectedMarketFee,
          checkoutConfig.data?.fees ?? [],
          selectedVendor,
          govFee),
        market_name: marketName ?? ""
      }
        
      //Submit Checkout
      callEndpoint({
        endpointCall: SubmitCheckout(CheckoutSubmitData),
        onSuccess: (response) => {
          DisplaySuccessAlert(response.message);
        },
        onError: (errorCode) => {
          DisplayErrorAlert("Checkout failed", "Network Error", errorCode)
        }
      });
    }
  }


  ////////////////////////////////
  //        USE EFFECTS         //
  ////////////////////////////////

  // On Mount
  // NOTE: custom result state handling due to complex state setting.
  useEffect(() => {

    if (marketId && date) {

      //Set Loading State
      setCheckoutConfig((prevState) => ({
        ...prevState,
        state: APIResultState.LOADING,
      }));

      callEndpointWithState({
        endpointCall: GetCheckoutData(parseInt(marketId), toISOStringForSending(date)),
        onSuccess: (data, statusCode) => {

          //Set the nonchanging data
          setCheckoutConfig({
            data: { vendors: data.vendors, fees: data.market_fees },
            state: APIResultState.SUCCESS,
            status: statusCode ?? HttpStatusCode.Ok
          })

          //Setup token fields
          setTokens([...data.market_tokens.map((token) => createTokenFieldModel(0, token))])

          //Set Gov Fee if the market has one.
          setGovFee(data.gov_fee ?? 0)
        },
        onError: (errorCode) => {
          DisplayAlert('error', "Could not get checkout data.", "Network Error", errorCode)
        }
      })
    }
  }, []);

  // Recalculate the market fee when the model or tokens change
  useEffect(() => {

    /* Calculate new market fee */
    const newMarketFee = calculateMarketFee();
    setMarketFee(newMarketFee)

    /* Subtract out the token values */
    const tokensValue = Tokens.reduce((acc, token) => {
      const tokenValue = token.quantity * token.token.per_dollar_value;
      return acc + tokenValue
    }, 0);
    setMoneyOwed(newMarketFee - tokensValue)

  }, [selectedMarketFee, Tokens, grossProfit]);

  // Update the market fee model when the vendor changes
  useEffect(() => {

    if (checkoutConfig.data) {

      const fee = checkoutConfig.data.fees.find(
        (fee: MarketFee) => fee.vendor_type === selectedVendor?.type) || null

      setSelectedMarketFee(fee);
      refreshCPCBanner();
    }

  }, [selectedVendor]);


  // Update the schema dynamically based on tokens
  const dynamicSchema = useMemo(() => {
    const tokenSchema = Tokens.reduce((acc, token) => {
      acc[token.token.type] = z.number().min(0, `${token.token.type} must be at least 0.`);
      return acc;
    }, {} as Record<string, z.ZodNumber>);

    return z.object({
      vendor: z.number().min(1, "Vendor is required."),
      gross_profit: z.number().min(1, "Gross Profit is required."),
      ...tokenSchema,
    });
  }, [Tokens]);

  return (
    <MSMPage
      title="Checkout"
      titleDescription={`${marketName} on ${toReadableDate(date ?? Date())}`}
      padContent={false}>

      <APIResultDisplay result={checkoutConfig}>
        {(data) => (
          <MSMForm
            schema={dynamicSchema}
            onSubmit={verifyCheckout}
            onReset={resetCheckoutState}
            persistOnReset={['vendor']}
            autoFocusField="gross_profit"
            clearOnSubmit
            centerSubmitButton
            isAuto
            defaultValues={{ "vendor": data.vendors[0] }}   //Sets form value, to sync with dropdown value
          >
            {(isProducer(selectedVendor?.type) &&
              vendorCPCStatus !== CPCStatus.UP_TO_DATE) &&
              <div className="mb-2">
                <BannerAlert variant="error" title={"CPC Alert"} text={CPCBannerText} />
              </div>
            }

            <div className="flex flex-row gap-x-1 space-x-4 items-end">
              <div className="flex-grow">
                <MSMFormField name="vendor" label="Vendor" >
                  {({ field, focusNextField }) => (
                    <MSMDropdown
                      items={convertToDropdownItems(data.vendors, "business_name", "market_vendor_id")}
                      placeholder="Select a vendor..."
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        setSelectedVendor(data.vendors.find((v) => v.market_vendor_id === Number(value)));
                      }}
                      focusNext={focusNextField}
                      ref={field.ref}
                    />
                  )}
                </MSMFormField>
              </div>
              <div className="mb-2 min-w-6">
                <MSMVendorBadge vendorType={selectedVendor?.type} />
              </div>
            </div>
            <DescribeText
              text={"Fee Calc: " + getMarketFeeCalculationString(selectedMarketFee, data.fees, selectedVendor, govFee)}
              className="ml-1 mt-2 mb-2"
              italic={false}
            />

            <MSMHorizontalDivideLine />

            <MSMFlexGrid>

              {/* Gross Profit field */}
              <MSMFormField
                key={"GROSS_PROFIT"}
                name={"gross_profit"}
                label={"Gross Profit"}>
                {({ field, focusNextField }) => (
                  <MSMNumericalInput
                    min={0}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      setGrossProfit(value);
                    }}
                    focusNext={focusNextField}
                    ref={field.ref}
                  />
                )}
              </MSMFormField>

              {/* Token Fields */}
              {Tokens.map((token: TokenFieldModel, index: number) => (
                <MSMFormField 
                  key   = {token.token.type}
                  name  = {token.token.type}
                  label = {getTokenLabel(token.token)}>
                  {({ field, focusNextField }) => (
                    <MSMNumericalInput
                      min={0}
                      value={field.value}
                      onChange={(quantity) => {
                        const quantityValue = quantity === undefined ? 0 : quantity
                        handleTokensChanged(quantityValue, index);
                        field.onChange(quantity)
                      }}
                      focusNext={focusNextField}
                      ref={field.ref}
                    />
                  )}
                </MSMFormField>
              ))}
            </MSMFlexGrid>
            <DescribeText
              justifyCenter={false}
              text={`Owed = Fee${govFee ? " + Gov. Fee" : ""}${!isEmptyList(Tokens) ? " - Token Total" : ""}`}>
              <span className="text-3xl font-bold self-right">Money Owed: </span>
              <MSMMoneyDisplay
                value={moneyOwed}
                className={`text-3xl text-right font-bold ${moneyOwed < 0 ? "text-destructive" : "text-primary"}`} />
            </ DescribeText>

            {/* <MSMSplitView className="text-left py-8"
              right={

              }
              left={
                <DescribeText text={getMarketFeeCalculationString(selectedMarketFee, data.fees, selectedVendor, grossProfit)}>
                  <span className="text-3xl font-bold">Market Fee</span><br />
                  <MSMMoneyDisplay
                    value={marketFee}
                    className="text-2xl" />
                </DescribeText>
              }
            /> */}


          </MSMForm>
        )}
      </APIResultDisplay>

    </MSMPage>
  );

};

export default Checkout;
