import { AxiosResponse } from "axios";
import { axiosInstance } from "@lib/APICaller";
import { CheckoutOption, CheckoutData, CheckoutSubmit } from "@typings/DataModels/Checkout";


export const GetCheckoutOptions = async (): Promise<AxiosResponse<CheckoutOption[]>> => {
    const urlString: string = `/market_manager/checkout/market_date_options`;
    return axiosInstance.get<CheckoutOption[]>(urlString)
};

export const GetCheckoutData = async (MarketID: number, MarketDate: string): Promise<AxiosResponse<CheckoutData>> => {
    const urlString = `/market_manager/checkout/market_details/${MarketID}?market_date=${MarketDate}`;
    return axiosInstance.get<CheckoutData>(urlString)
};

export const SubmitCheckout = (Data: CheckoutSubmit): Promise<AxiosResponse> => {
    let urlString: string = `/market_manager/checkout/submit`;
    return axiosInstance.post(urlString, Data);
}