import { ColumnDef } from "@tanstack/react-table"
import { DataTableColumnHeader } from "@MSMComponents/DataTable/DataTableColumnHeader"
import { z } from "zod"
import { CPCStatusBadge } from "@MSMComponents/CPCComponents/CPCStatusBadge";
import { DataTableRowActions } from "@MSMComponents/DataTable/DataTableRowActions";
import { MSMVendorBadge } from "@MSMComponents/DataDisplay/MSMVendorBadge";
import { VendorType } from "@typings/Enums";
import { VendorReportSchema } from "@typings/ReportSchemas";
import { isProducer } from "@utilities";

export type VendorData = z.infer<typeof VendorReportSchema>

export const VendorManagementColumns: ColumnDef<VendorData>[] = [
  {
    accessorKey: "business_name",
    header: ({ column }) => (<DataTableColumnHeader column={column} title="Business name" />),
    cell: ({ row }) => <div className="tbl-col">{row.getValue("business_name")}</div>,

    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "type",

    header: ({ column }) => (<DataTableColumnHeader column={column} title="Type" />),
    cell: ({ row }) => {
      let vendor_type: VendorType = row.getValue("type")
      return (<div className="tbl-col">{<MSMVendorBadge vendorType={vendor_type} />}</div>)
    },

    filterFn: (row, type, value) => {
      return value.includes(row.getValue(type))

    }
  },
  {
    accessorKey: "current_cpc",
    header: ({ column }) => (<DataTableColumnHeader column={column} title="CPC" />),
    cell: ({ row }) => {
      let displayText: string = isProducer(row.getValue("type")) ? row.getValue('current_cpc') : "N/A"
      return (<div className="tbl-col">{displayText}</div>)
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "cpc_expr",
    header: ({ column }) => (<DataTableColumnHeader column={column} title="CPC Expr" />),
    cell: ({ row }) => {
      return (
        <div className="justify-between">
          {isProducer(row.getValue("type")) ? (
            <CPCStatusBadge date={row.getValue("cpc_expr")} />
          ) : (
            "N/A"
          )}
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]