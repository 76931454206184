import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../App/authStore';
import MSMForm from '@MSMComponents/Form Flow/MSMForm';
import MSMFormField from '@MSMComponents/Form Flow/MSMFormField';
import MSMTextInput from '@MSMComponents/Inputs/MSMTextInput';
import { LoginFormSchema } from '@typings/FormSchemas';

function Login() {
    const navigate = useNavigate();
    const login = useAuthStore(state => state.login);

    const [err, setErr] = useState<string | undefined>(undefined);

    const handleLogin = async (data: any) => {
        try {
            await login(data);
            navigate('/');
        } catch {
            setErr("Incorrect username or password.\n")
        }
    };

    return (
        <>
            <MSMForm
                schema={LoginFormSchema}
                onSubmit={handleLogin}
                submitButtonText='Login'
                autoFocusField='username'
                isAuto>

                {/* USERNAME */}
                <MSMFormField name="username" label="Username">
                    {({ field, focusNextField }) =>
                        <MSMTextInput
                            value={field.value}
                            onChange={field.onChange}
                            ref={field.ref}
                            focusNext={focusNextField}
                        />
                    }
                </MSMFormField>

                {/* PASSWORD */}
                <MSMFormField name="password" label="Password">
                    {({ field, focusNextField }) =>
                        <MSMTextInput
                            value={field.value}
                            onChange={field.onChange}
                            ref={field.ref}
                            focusNext={focusNextField}
                            type='password'
                        />
                    }
                </MSMFormField>
            </MSMForm>
            <div className="mt-4 text-gray-600 text-sm">
                Don't have an account? <a href="/create_account" className="text-blue-600 hover:text-blue-800">Create one!</a>
            </div>
        </>
    );
}

export default Login;