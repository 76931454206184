import { useNavigate } from 'react-router-dom';
import LandingButton from '@MSMComponents/Buttons/LandingButton';
import { IconSrcs } from '@MSMComponents/MSMIcon/Icon';

const Home = () => {

  const navigate = useNavigate();

  return (
    <div className="pc-c" >

      <h1 className='text-center'>Main Street Market</h1>

      <div className="flex flex-col justify-evenly h-full w-8/12">

        <LandingButton
          imageSrc={IconSrcs.Checkout}
          text="Checkout"
          onClick={() => { navigate('checkout_options') }} />

        <LandingButton
          imageSrc={IconSrcs.VendorManagement}
          text="Vendors"
          onClick={() => { navigate("vendormanagement") }} />

        <LandingButton
          imageSrc={IconSrcs.Reporting}
          text="Reporting"
          onClick={() => { navigate("reporting") }} />

      </div>

    </div>

  );
};

export default Home;