import { z } from "zod";
import { TokenType, VendorType } from "./Enums";

export const CheckoutReportInputSchema = z.object({
    market: z.number(),
    date: z.string().min(1, "Date selection is required."),
    vendorType: z.string()
  });

export const TokenSchema = z.object({
    type: z.enum([TokenType.EBT, TokenType.MARKET_MATCH, TokenType.ATM]),
    count: z.number(),
    per_dollar_value: z.number()
  })
  
export const CheckoutReportSchema = z.object({
    id: z.string(),
    vendor_name: z.string(),
    vendor_type: z.nativeEnum(VendorType),
    reported_gross: z.number(),
    market_fee: z.number(),
    money_owed: z.number(),
    market_date: z.date(),
    tokens: z.array(TokenSchema)
  })

export const VendorReportSchema = z.object({
    id: z.number(),
    business_name: z.string(),
    type: z.string(),
    current_cpc: z.string(),
    cpc_expr: z.string(),
  })