import React, { useEffect, useState } from 'react';
import { usePopupStore } from './PopupStore';
import ReactDOM from 'react-dom';
import PrimaryButton from '@MSMComponents/Buttons/PrimaryButton';
import { Button } from '@ShadcnComponents/ui/button';
import { X } from 'lucide-react';

const Modal = () => {
    const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setPortalElement(document.getElementById('modal-root'));
    }, []);

    const { modal, closeModal } = usePopupStore();

    if (!modal || !modal.content || !portalElement) return null;

    const handleCancel = () => {
        modal.onCancel?.()
        closeModal();
    };

    const handleConfirm = async () => {
        const isSuccessful = await modal.onConfirm?.()
        if (isSuccessful) {
            closeModal();
        }
    };

    return ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Backdrop */}
            <div
                className="fixed inset-0 bg-black/65"
                onClick={closeModal}
            />

            {/* Modal Content */}
            <div className="flex flex-col relative z-50 w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
                {/* Close Button */}
                <button onClick={closeModal} className="absolute right-4 top-4 text-gray-500 hover:text-gray-700">
                    <X size={20} strokeWidth={2.2} />
                </button>

                {/* Title */}
                {modal.title && (
                    <h2 className="text-lg font-semibold">{modal.title}</h2>
                )}

                {/* Content */}
                <div className="mt-2">
                    {modal.content}
                </div>

                <div className="mt-4 flex space-x-3 justify-end">
                    {/* Cancel Button */}
                    {modal.onCancel && (
                        <Button variant="outline" onClick={handleCancel}>
                            {modal.cancelText}
                        </Button>
                    )}

                    {/* Submit Button */}
                    {modal.onConfirm && (
                        <PrimaryButton
                            text={modal.confirmText ? modal.confirmText : "Confirm"}
                            onClick={handleConfirm}
                        />
                    )}
                </div>
            </div>
        </div>,
        portalElement
    );
};

export default Modal;