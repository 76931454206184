import { z } from "zod"

import { toReadableDate } from "Utilities"
import { TokenType, VendorType } from "@typings/Enums"

import { ColumnDef } from "@tanstack/react-table"
import { DataTableColumnHeader } from "@MSMComponents/DataTable/DataTableColumnHeader"
import { DataTableRowActions } from "@MSMComponents/DataTable/DataTableRowActions"

import { TokenReport } from "@typings/DataModels/VendorReport"

import MSMMoneyDisplay from "@MSMComponents/DataDisplay/MSMMoneyDisplay"
import { MSMVendorBadge } from "@MSMComponents/DataDisplay/MSMVendorBadge"
import { CheckoutReportSchema } from "@typings/ReportSchemas"

export const calculateNetProfit = (fees_paid: number, tokens: TokenReport[]): number => {
  if (tokens == undefined) { return fees_paid; }
  const totalTokenValue = tokens.reduce((total, token) => {
    return total + token.count * token.per_dollar_value;
  }, 0);
  return fees_paid - totalTokenValue;
};

type ReportsColumnsType = z.infer<typeof CheckoutReportSchema>

const BaseStartReportingColumns: ColumnDef<ReportsColumnsType>[] = [
  {
    accessorKey: "business_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Vendor Name" />
    ),
    cell: ({ row }) => {
      let businessName: string = row.getValue("business_name")
      return (
        <div className="tbl-col">{businessName}</div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "vendor_type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
    cell: ({ row }) => {
      let vendorType: VendorType = row.getValue("vendor_type")
      return (
        <MSMVendorBadge vendorType={vendorType} />
      )
    },
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "market_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Market Date" />
    ),
    cell: ({ row }) =>
      <div className="tbl-col">
        {toReadableDate(row.getValue("market_date"))}
      </div>,
  },
  {
    accessorKey: "tokens",
    header: undefined, // Not displayed, but ensures tokens are accessible
    cell: undefined, // No visible content for this column
  },
]

const tokenColumns = (tokens: TokenReport[]): ColumnDef<ReportsColumnsType>[] => {
  //If there are no tokens...
  if (tokens === null) { return []; }

  return tokens.map(token => ({
    accessorKey: `${token.type}`, // This is a virtual accessor. We'll handle the cell manually.
    header: ({ column }) => <DataTableColumnHeader column={column} title={TokenType.toString(token.type)} />,
    cell: ({ row }) => {
      let rowTokens: TokenReport[] = row.getValue("tokens");
      let rowToken: TokenReport | undefined = rowTokens.find((t) => t.type == token.type)
      return (<span>{rowToken ? rowToken.count : "N/A"}</span>);
    },
    enableSorting: false,
  }))
};

const BaseEndReportingColumns: ColumnDef<ReportsColumnsType>[] = [
  {
    accessorKey: "market_fee",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Fee" />
    ),
    cell: ({ row }) => {
      let fee: number = row.getValue("market_fee")
      return (<MSMMoneyDisplay value={fee} />)
    },

    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "money_owed",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Money Owed" />
    ),
    cell: ({ row }) => {
      let moneyOwed: number = row.getValue("money_owed")
      let textColor = moneyOwed > 0 ? "text-green-600" : moneyOwed < 0 ? "text-red-600" : ""
      return (
        <MSMMoneyDisplay className={textColor} value={moneyOwed} />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },

]

export const ReportingColumns = (tokens: TokenReport[]): ColumnDef<ReportsColumnsType>[] => {
  return [...BaseStartReportingColumns, ...tokenColumns(tokens), ...BaseEndReportingColumns]
}