// BaseButton.tsx
import React, { Children, useState } from 'react';
import "../../index.css";
import { Button } from '@ShadcnComponents/ui/button';

interface ButtonStyleProps {
  backgroundColor?: string;
  color?: string;
  hoverBackgroundColor?: string;
  hoverColor?: string;
  hoverOpacity?: number;
}

export interface BaseButtonProps extends ButtonStyleProps {
  text?: string;
  onClick?: () => void;
  size?: "default" | "lg" | "sm" | "icon" | null | undefined;
  variant?: 'default' | 'destructive' | 'ghost' | 'outline' | 'secondary' | 'link';
  disabled?: boolean;
  Icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  fullWidth?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const BaseButton: React.FC<BaseButtonProps> = ({
  text,
  onClick,
  size: btnSize = 'default',
  variant: btnVariant = 'default',
  disabled: isDisabled = false,
  Icon = null,
  iconPosition = 'left',
  fullWidth = false,
  children = <></>,
  className = ""
}) => {

  const [disabled, setDisabled] = useState(isDisabled); 
  
  return (
    <Button
      className={`font-semibold ${className}`}
      variant={btnVariant}
      size={btnSize}
      onClick={disabled ? () => {} : onClick}
      disabled={isDisabled}
      >

      {Icon != null && iconPosition === 'left' && Icon}
      {text || children}
      {Icon != null && iconPosition === 'right' && Icon}
    </Button>
  );
};

export default BaseButton;