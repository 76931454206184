import { z } from "zod";
import MSMForm from "@MSMComponents/Form Flow/MSMForm";
import MSMFormField from "@MSMComponents/Form Flow/MSMFormField";
import MSMTextInput from "@MSMComponents/Inputs/MSMTextInput";
import MSMEnumDropdown from "@MSMComponents/Inputs/MSMEnumDropdown";
import MSMFlexGrid from "@MSMComponents/Layout/MSMFlexGrid";
import { axiosInstance } from "@lib/APICaller";
import { MSMRole } from "@typings/Enums";

// Define the form validation schema
const CreateUserSchema = z.object({
    firstname: z.string()
        .min(1, "First name is required.")
        .max(50, "First name must not exceed 50 characters."),
    lastname: z.string()
        .min(1, "Last name is required.")
        .max(50, "Last name must not exceed 50 characters."),
    email: z.string()
        .email("Invalid email address.")
        .optional()
        .nullable(),
    role: z.nativeEnum(MSMRole, {
        errorMap: () => ({ message: "Please select a valid role." })
    }),
    username: z.string()
        .min(1, "Username is required.")
        .max(50, "Username must not exceed 50 characters."),
    password: z.string()
        .min(8, "Password must be at least 8 characters.")
        .max(50, "Password must not exceed 50 characters.")
        .regex(/[A-Z]/, "Password must contain at least one uppercase letter.")
        .regex(/[a-z]/, "Password must contain at least one lowercase letter.")
        .regex(/[0-9]/, "Password must contain at least one number."),
    confirmPassword: z.string()
        .min(1, "Please confirm your password.")
}).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"] // This shows the error on the confirmPassword field
});

// API function for creating a user
const createUser = async (userData: Omit<z.infer<typeof CreateUserSchema>, 'confirmPassword'>) => {
    const response = await axiosInstance.post('/user/create', userData);
    return response.data;
};

const CreateUserForm = () => {

    const handleCreateUser = async (values: z.infer<typeof CreateUserSchema>) => {

        // Destructure confirmPassword out and keep the rest of the values
        const { confirmPassword, ...postBody } = values;
        try {
            await createUser(postBody);
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    return (
        <MSMForm
            schema={CreateUserSchema}
            onSubmit={handleCreateUser}
            submitButtonText='Create Account'
        >
            <MSMFlexGrid>
                {/* First Name */}
                <MSMFormField name="firstname" label="First Name">
                    {({ field }) => (
                        <MSMTextInput
                            value={field.value}
                            onChange={field.onChange}
                            ref={field.ref}
                        />
                    )}
                </MSMFormField>

                {/* Last Name */}
                <MSMFormField name="lastname" label="Last Name">
                    {({ field }) => (
                        <MSMTextInput
                            value={field.value}
                            onChange={field.onChange}
                            ref={field.ref}
                        />
                    )}
                </MSMFormField>

                {/* Email */}
                <MSMFormField name="email" label="Email (Optional)">
                    {({ field }) => (
                        <MSMTextInput
                            value={field.value}
                            onChange={field.onChange}
                            ref={field.ref}
                            type="email"
                        />
                    )}
                </MSMFormField>

                {/* Role */}
                <MSMFormField name="role" label="Role">
                    {({ field }) => (
                        <MSMEnumDropdown
                            enumObject={MSMRole}
                            onChange={field.onChange}
                            ref={field.ref}
                        />
                    )}
                </MSMFormField>

            </MSMFlexGrid>


            {/* Username */}
            <MSMFormField name="username" label="Username">
                {({ field }) => (
                    <MSMTextInput
                        value={field.value}
                        onChange={field.onChange}
                        ref={field.ref}
                    />
                )}
            </MSMFormField>

            {/* Password */}
            <MSMFormField name="password" label="Password">
                {({ field }) => (
                    <MSMTextInput
                        value={field.value}
                        onChange={field.onChange}
                        ref={field.ref}
                        type="password"
                    />
                )}
            </MSMFormField>

            {/* Confirm Password */}
            <MSMFormField name="confirmPassword" label="Confirm Password">
                {({ field }) =>
                    <MSMTextInput
                        value={field.value}
                        onChange={field.onChange}
                        ref={field.ref}
                        type="password"
                    />
                }
            </MSMFormField>
        </MSMForm>
    );
};

export default CreateUserForm;