import { toReadableString } from "Utilities";

export enum VendorType {
    PRODUCER = "PRODUCER",
    NON_PRODUCER = "NON_PRODUCER",
    ANCILLARY = "ANCILLARY"
}

export enum FeeType {
    PERCENT_GROSS = "PERCENT_GROSS",
    FLAT_FEE = "FLAT_FEE",
    FLAT_PERCENT_COMBO = "FLAT_PERCENT_COMBO",
    MAX_OF_EITHER = "MAX_OF_EITHER",
    GOV_FEE = "GOV_FEE"
}

export enum TokenType {
  EBT = "EBT",
  MARKET_MATCH = "MARKET_MATCH",
  ATM = "ATM"
}

export enum MSMRole {
  MARKET_MANAGER = "MARKET_MANAGER",
  VENDOR = "VENDOR",
  CUSTOMER = "CUSTOMER"
}

export namespace TokenType {
  export function toString(token: TokenType): string {
    switch (token){
      case TokenType.ATM:
        return "ATM"
      case TokenType.EBT:
        return "EBT"
      default:
        return toReadableString(token);
  }
}
}

export enum CPCStatus {
  PAST_DUE = "Past Due",
  URGENT = "Due Urgently",
  WARNING = "Due Soon",
  UP_TO_DATE = "Up to Date",
  NONE = "No CPC found"
}
