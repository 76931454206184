import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { usePopupStore } from './PopupStore';
import { CircleAlert, X } from 'lucide-react';

const getAlertStyles = (variant: string) => {
  switch (variant) {
    case 'error':
      return 'border-destructive text-destructive';
    case 'info':
      return 'border-gray-500 text-gray-600';
    case 'warning':
      return 'border-warning text-warning';
    case 'success':
      return 'border-success text-success';
    default:
      return 'border-gray-500 text-gray-600';
  }
};

export const AlertBanner: React.FC = () => {
  const { alert, hideAlert } = usePopupStore();

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(hideAlert, 5000);
      return () => clearTimeout(timer);
    }
  }, [alert, hideAlert]);

  if (!alert) return null;

  const colorClasses = getAlertStyles(alert.type);

  return ReactDOM.createPortal(
    <div className={`fixed top-4 left-1/2 -translate-x-1/2 w-fit min-w-[400px] bg-popover rounded border-2 ${colorClasses} shadow-lg z-50 p-4 flex items-center`}>
      <CircleAlert className={colorClasses} size={20} strokeWidth={2.2} />
      <div className="ml-3">
        {alert.title && <h4 className={`font-medium ${colorClasses}`}>{alert.title}</h4>}
        <p className="text-gray-600">{alert.message}{alert.networkStatus ? ` (Status: ${alert.networkStatus})` : ''}</p>
      </div>
      <button onClick={hideAlert} className={`ml-auto ${colorClasses} hover:opacity-75`}>
        <X size={20} strokeWidth={2.2} />
      </button>
    </div>,
    document.getElementById('alert-root') as HTMLElement
  );
};