import { z } from 'zod';
import { useEffect, useRef, useState } from 'react';

import { Vendor } from '@typings/DataModels/Common';
import { CreateVendor } from '@typings/DataModels/Vendor';

import { DisplayModal } from '@MSMComponents/Popups/PopupHelpers';
import { usePopupStore } from '@MSMComponents/Popups/PopupStore';
import { callEndpoint } from '@lib/APICaller';

import MSMPage from '@MSMComponents/Layout/MSMPage';
import CreateVendorForm from 'Forms/CreateVendorFrom';
import { CreateVendorType, GetMarketsOptions, GetMarketVendors } from './VendorManagmentAPICalls';
import { DisplayAlert } from '@MSMComponents/Popups/PopupHelpers';
import MSMDropdown, { convertToDropdownItems, MSMDropdownItem } from '@MSMComponents/Inputs/MSMDropdown';
import MSMForm, { MSMFormRef } from '@MSMComponents/Form Flow/MSMForm';
import MSMFormField from '@MSMComponents/Form Flow/MSMFormField';
import MSMRow from '@MSMComponents/Layout/MSMRow';
import MSMHorizontalDivideLine from '@MSMComponents/Layout/MSMHorizontalDivideLine';
import { DataTable } from '@MSMComponents/DataTable/DataTable';
import { VendorManagementColumns } from './VendorManagementColumns';
import AddVendorToMarketsForm from 'Forms/AddVendorToMarketsForm';
import SecondaryButton from '@MSMComponents/Buttons/SecondaryButton';

interface VendorTableRow {
  id: number;
  business_name: string;
}

// Function to map vendors to VendorTableRow and concatenate them while excluding duplicates
const mapAndDeduplicateVendors = (vendors: Vendor[]): VendorTableRow[] => {
  const vendorTableRows: VendorTableRow[] = [];
  vendors.forEach((vendor) => {
    if (!vendorTableRows.some((row) => row.id === vendor.id)) {
      vendorTableRows.push(vendor);
    }
  });
  return vendorTableRows;
};


const VendorManagement = () => {


  const { closeModal } = usePopupStore()

  const [rows, setRows] = useState<any[]>([]);
  const [marketOptions, setMarketOptions] = useState<MSMDropdownItem[]>([])

  const formRef = useRef<MSMFormRef>(null);

  const onModalConfirm = async () => {
    return await formRef.current?.submit()
  }

  const onVendorCreated = (vendorId: number, newVendor: CreateVendorType) => {
    console.log("Here with:", vendorId)
    DisplayModal(
      {
        title: `${newVendor.business_name} was created!`,

        content: <AddVendorToMarketsForm
          vendorId={vendorId}
          vendorName={newVendor.business_name}
          markets={marketOptions.slice(1)}
          onSubmit={closeModal}
        />,
      }
    )
  }

  const getVendors = (market_id: number) => {
    callEndpoint({
      endpointCall: GetMarketVendors(market_id),
      onSuccess: (data) => {
        setRows(mapAndDeduplicateVendors(data))
      },
      onError: (errorCode) => {
        DisplayAlert('error', "Could not retrieve vendors", "Network Error", errorCode)
      }
    });
  }

  useEffect(() => {
    callEndpoint({
      endpointCall: GetMarketsOptions(),
      onSuccess: (data) => {
        setMarketOptions(convertToDropdownItems(data, "market", "market_id"))
      },
      onError: (errorCode) => {
        DisplayAlert('error', "Could not retrieve market options", "Network Error", errorCode)
      }
    });
  }, [])

  return (

    <MSMPage title='Vendor Managment'>
      <MSMRow justify='end' align='end' lastElementRight>
        <MSMForm
          schema={z.object({ "market": z.number() })}
          onSubmit={(data) => getVendors(data.market)}
          submitButtonText='Retrieve Vendors'
          row>
          {/* Market Selection */}
          <MSMFormField name="market" label="Market">
            {({ field }) => (
              <MSMDropdown
                items={marketOptions}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          </MSMFormField>
        </MSMForm>

        <SecondaryButton
          text="Add Vendor"
          onClick={() =>
            DisplayModal(
              {
                title: "Add Vendor",
                content: <CreateVendorForm ref={formRef} onVendorCreated={onVendorCreated} />,
                onConfirm: onModalConfirm
              }
            )
          }
        />
      </MSMRow>
      <MSMHorizontalDivideLine />
      <DataTable data={rows} columns={VendorManagementColumns} />

    </MSMPage>

  );
};

export default VendorManagement;