import React from "react";
import { AlertCircle } from "lucide-react";
import {
  Alert as ShadAlert,
  AlertDescription,
  AlertTitle,
} from "@ShadcnComponents/ui/alert";
import { AlertType } from "./PopupStore";
import { X } from "lucide-react"

interface AlertComponentProps {
  variant: AlertType;
  title: string;
  text: string;
  onClose?: () => void;
  className?: string;
}

export const BannerAlert: React.FC<AlertComponentProps> = ({
  variant,
  title,
  text,
  onClose,
  className = ""
}) => {


  const determineShadVariant = (variant: AlertType) => {
    switch (variant) {
      case "error":
        return "destructive";
      case "warning":
        return "warning";
      case "info":
        return "info";
      case "success":
        return "default";
    }
  };

  return (
    <ShadAlert
      variant={determineShadVariant(variant)}
      className={`border-2 bg-background ${className}`}>
      <div className="flex">
        {(variant === "warning" || variant === "error") &&
          <AlertCircle className="h-6 w-6" />}
        <div className="ml-2 mt-1">
          <AlertTitle className="font-semibold">{title}</AlertTitle>
          <AlertDescription>{text}</AlertDescription>
        </div>
        {(onClose && <X className="h-4 w-4 absolute right-2 top-2 " onClick={onClose} />)}
      </div>

    </ShadAlert>
  );
};
