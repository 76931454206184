import { z } from "zod";
import { CreateVendorFormSchema } from "@typings/FormSchemas";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../../lib/APICaller";
import { Vendor, MarketDropdownOption, ProducerContact } from "@typings/DataModels/Common";

export type CreateVendorType = z.infer<typeof CreateVendorFormSchema> & { producer_contacts?: ProducerContact[] };

export const GetMarketVendors = async ( MarketId: number): Promise<AxiosResponse<Vendor[]>> => {
    let urlString = `/market/${MarketId}/vendors`;
    return axiosInstance.get<Vendor[]>(urlString);
}

export const GetMarketsOptions = async (): Promise<AxiosResponse<MarketDropdownOption[]>> => {
    let urlString = `/market_manager/market_options`;
    return axiosInstance.get<MarketDropdownOption[]>(urlString);
}
