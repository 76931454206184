import { AxiosResponse } from "axios";
import { axiosInstance } from "../../lib/APICaller";
import { ReportingOption, CompleteVendorReport } from "@typings/DataModels/VendorReport";

export const GetReportingOptions = async (): Promise<AxiosResponse<ReportingOption[]>> => {

    const urlString = `/market_manager/reporting/options`;
    return axiosInstance.get<ReportingOption[]>(urlString);

  };

export const GetVendorReport = async (MarketID: number, MarketDate: string, VendorType: string): Promise<AxiosResponse<CompleteVendorReport>> => {
    
    const M_ID = "market_id=" + MarketID + "&";
    const M_D = MarketDate === "" ? "" : "market_date=" + MarketDate + "&";
    const V_T = VendorType === "" ? "" : "vendor_type=" + VendorType + "&";
    const urlString = `market_manager/reporting/report?${M_ID}${M_D}${V_T}sort_by=market_date&sort_direction=DESC`

    return axiosInstance.get<CompleteVendorReport>(urlString);
}