import './App.css';
import Home from '../Home/Home';
import Checkout from '../Checkout/Checkout'
import Reporting from '../CheckoutReport/CheckoutReport';
import VendorManagement from '../Vendor Management/VendorManagement';
import { BrowserRouter, Navigate, Route, Routes, } from "react-router-dom";
import { AlertBanner } from '@MSMComponents/Popups/Alerts';
import { usePopupStore } from '@MSMComponents/Popups/PopupStore';
import CheckoutOptions from '@Pages/Checkout/CheckoutOptions';
import { useAuthStore } from './authStore';
import { memo, useEffect } from 'react';
import Login from '@Pages/Login/Login';
import { useShallow } from 'zustand/react/shallow';
import Modal from '@MSMComponents/Popups/Modal';
import CreateUserForm from 'Forms/CreateAccountForm';

function App() {

  const { verifyToken } = useAuthStore();

  //ON APP LOAD
  useEffect(() => {
    verifyToken();
  }, []);

  // Memoized Protected Route Component
  const ProtectedRoute = memo(({ children }: { children: JSX.Element }) => {

    const isAuthenticated = useAuthStore(useShallow((state) => state.isAuthenticated));
    const isLoading = useAuthStore(useShallow((state) => state.isLoading));

    if (isLoading) return <div>Loading...</div>;
    if (!isAuthenticated) return <Navigate to="/login" />;
    return children

  });

  // Login Route Component
  const LoginRoute = () => {
    const isAuthenticated = useAuthStore(state => state.isAuthenticated);
    return isAuthenticated ? <Navigate to="/" /> : <Login />;
  };

  // Separate the route config
  const PROTECTED_ROUTES = [
    { path: "/", element: Home },
    { path: "/checkout_options", element: CheckoutOptions },
    { path: "/checkout", element: Checkout },
    { path: "/reporting", element: Reporting },
    { path: "/vendormanagement", element: VendorManagement }
  ] as const;


  return (
    <div className={`app-container App`}>
      <AlertBanner />
      <Modal />
      <header className="App-header">
        <BrowserRouter>
          <Routes>

            {/* Non-Protected Routes */}
            <Route path="/login" element={<LoginRoute />} />
            <Route path="/create_account" element={<CreateUserForm />} />

            {/* Protected Routes */}
            {PROTECTED_ROUTES.map(({ path, element: Element }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ProtectedRoute>
                    <Element />
                  </ProtectedRoute>
                }
              />
            ))}
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
