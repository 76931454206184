import React from "react";
import { AlertIcon } from "./AlertIcon";
import { cn } from "@lib/utils";
import { calculateCPCStatus } from "Utilities";
import { CPCStatusColors } from "@typings/Constants";
import { CPCStatus } from "@typings/Enums";
import { stat } from "fs";


interface CPCStatusBadgeProps {
  date: string | undefined; // ISO string or date format compatible with `Date`
}

export const CPCStatusBadge: React.FC<CPCStatusBadgeProps> = ({ date }) => {

  const { status, daysLeft } = calculateCPCStatus(date ? new Date(date) : undefined);
  const dueText = `CPC due in `;

  const tooltipContent = (() => {
    if (status === CPCStatus.PAST_DUE) {
      return <span className="font-bold text-destructive">{Math.abs(daysLeft)} Days Past Due</span>;
    } else if (status === CPCStatus.NONE) {
      return <span className="font-bold text-destructive">No CPC found, please submit as soon as possible.</span>;
    }

    const dayCount = (
      <span
        className={cn(
          status === CPCStatus.URGENT || CPCStatus.NONE ? "font-bold text-destructive" : "",
          status === CPCStatus.WARNING ? "font-bold text-warning" : ""
        )}
      >
        {daysLeft}
      </span>
    );

    return (
      <>
        {dueText}
        {dayCount}
        {status === CPCStatus.UP_TO_DATE ? "." : "!"}
      </>
    );
  })();

  return (
    <div className="flex justify-between w-28">
      {date ? (
        <span className={CPCStatusColors[status]}>
          {new Date(date).toLocaleDateString()}
        </span>
      ) : (
        <span className={CPCStatusColors[status]}>No CPC</span>
      )}
      {status !== CPCStatus.UP_TO_DATE &&
        <AlertIcon color={CPCStatusColors[status]} tooltip={tooltipContent} />}
    </div>
  );
};
