import React from 'react';
import BaseButton, { BaseButtonProps } from './BaseButton';
import Icon from '../MSMIcon/Icon';

export interface LandingButtonProps extends BaseButtonProps {
  imageSrc?: string;
  imagePosition?: 'left' | 'right';
}


const LandingButton: React.FC<LandingButtonProps> = (props) => {
  return (
    <div style={{display: "flex"}}>
        <BaseButton
        {...props}
        className='px-20 py-16 font-bold text-3xl w-full'
        hoverOpacity={0.9}
        fullWidth={true}
        variant='default'
        disabled={props.disabled}
        Icon={props.imageSrc && <Icon iconSrc={props.imageSrc} color='white' size="2em" />}
        >
          {props.children}
        </BaseButton>

    </div>
  );
};

export default LandingButton;
