
import { create } from 'zustand';
import { axiosInstance } from '@lib/APICaller';
import Cookies from 'js-cookie';


export const getCookie = (): string | undefined => {
  return process.env.REACT_APP_JWT_COOKIE && Cookies.get(process.env.REACT_APP_JWT_COOKIE);
}

interface LoginCredentials {
  username: string
  password: string
}

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  verifyToken: () => Promise<void>;
  login: (credentials: LoginCredentials) => Promise<void>;
  logout: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  isAuthenticated: false,
  isLoading: true,

  verifyToken: async () => {

    console.log("Verifying Token")

    // First check if we have a cookie
    const cookie = getCookie();
    if (cookie){
      try {
        // If we have a cookie, verify it with the backend
        const response = await axiosInstance.get('/verify-token');
        set({ isAuthenticated: true, isLoading: false });
        return;

      } catch (error) {
        // If verification fails, remove the invalid cookie
        Cookies.remove(process.env.REACT_APP_JWT_COOKIE!);     //Safe unwrap here, checked in getCookie()
      }
    }
    set({ isAuthenticated: false, isLoading: false })
  },

  login: async (credentials: LoginCredentials) => {

    console.log("Loggin In")

    //Necessary for FastAPI dependencies in the backend
    var bodyFormData = new FormData();
    bodyFormData.append("username", credentials.username)
    bodyFormData.append("password", credentials.password)
    bodyFormData.append("grant_type", "password")

    try {

      const response = await axiosInstance.post(
        '/token', 
        bodyFormData, 
        { headers: { "Content-Type": "multipart/form-data" }}
      );

      //Set Cookie value to returned token
      if (response.data.access_token && process.env.REACT_APP_JWT_COOKIE) {
        Cookies.set(process.env.REACT_APP_JWT_COOKIE, response.data.access_token, {
          expires: 1, // Cookie expires in 1 day
          secure: process.env.NODE_ENV === 'production', // Use secure in production
          sameSite: 'strict'
        });
      }
      set({ isAuthenticated: true, isLoading: false });
    } catch (error) {
      throw new Error('Invalid credentials');
    }
  },

  logout: async () => {
    try {
      await axiosInstance.post('/logout');
      // Remove the cookie on logout
      Cookies.remove(process.env.REACT_APP_JWT_COOKIE!);
      set({ isAuthenticated: false });
    } catch (error) {
      console.error('Logout failed:', error);
      // Still remove the cookie even if the logout request fails
      Cookies.remove(process.env.REACT_APP_JWT_COOKIE!);
      set({ isAuthenticated: false });
    }
  }

}));

export default useAuthStore;

