// PrimaryButton.tsx
import React from 'react';
import BaseButton, { BaseButtonProps } from './BaseButton';

const PrimaryButton: React.FC<BaseButtonProps> = (props) => {
  return (
    <div style={{display: "flex"}}>
        <BaseButton
        {...props}
        hoverOpacity={0.9}
        fullWidth={true}
        variant='default'
        disabled={props.disabled}
        //startIcon={props.imageSrc && <Icon iconSrc={props.imageSrc} color='accent' size="2em" />}
        >
          {props.children}
        </BaseButton>

    </div>
  );
};

export default PrimaryButton;