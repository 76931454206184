import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import MSMForm, { MSMFormRef } from '../MSMComponents/Form Flow/MSMForm';
import MSMDatePicker from "@MSMComponents/Inputs/MSMDatePicker";
import MSMTextInput from '@MSMComponents/Inputs/MSMTextInput';
import MSMFormField from '@MSMComponents/Form Flow/MSMFormField';
import MSMEnumDropdown from '@MSMComponents/Inputs/MSMEnumDropdown';

import { z } from "zod";
import { DisplayErrorAlert, DisplaySuccessAlert } from '@MSMComponents/Popups/PopupHelpers';
import { axiosInstance, callEndpoint } from '@lib/APICaller';
import { AxiosResponse } from 'axios';
import { ProducerContact } from '@typings/DataModels/Common';
import { CreateVendor } from '@typings/DataModels/Vendor';
import { VendorType } from '@typings/Enums';

import MSMFlexGrid from '@MSMComponents/Layout/MSMFlexGrid';
import MSMHorizontalDivideLine from '@MSMComponents/Layout/MSMHorizontalDivideLine';
import { CreateVendorFormSchema } from '@typings/FormSchemas';
import { CreateVendorType } from '@Pages/Vendor Management/VendorManagmentAPICalls';
import { isProducer } from '@utilities';


interface CreateVendorResponse {
  id: number;
  detail: string;
}

interface CreateVendorFormProps {
  onVendorCreated?: (vendorId: number, newVendor: CreateVendorType) => void; // Callback when a vendor is created
}

const createVendorEndpoint = async (NewVendor: CreateVendorType): Promise<AxiosResponse> => {
  let urlString = `/vendor/create`;
  return axiosInstance.post<CreateVendorResponse>(urlString, NewVendor);
}

const CreateVendorForm = forwardRef<MSMFormRef, CreateVendorFormProps>(
  ({ onVendorCreated }, ref) => {

    const [selectedVendorType, setSelectedVendorType] = useState<VendorType | undefined>(undefined);
    const [producerContacts, setProducerContacts] = useState<ProducerContact[]>([]);
    const formRef = useRef<MSMFormRef>(null);

    // Expose the formRef to the parent
    useImperativeHandle(ref, () => ({
      submit: async () => {
        if (formRef.current?.submit) {
          return await formRef.current.submit();
        }
        return false;
      },
      resetForm: () => {
        formRef.current?.resetForm?.(); // Call resetForm if available
      },
      isSubmitDisabled: formRef.current?.isSubmitDisabled ?? true, // Return the disabled state
    }));


    const createVendor = (formData: z.infer<typeof CreateVendorFormSchema>) => {

      /* Handles edge case:
        1.) Producer selected 
        2.) CPC or CPC Expiration changed
        3.) Switch back to non-producer or ancillary

        We will need to clear the CPC and CPC Expiration fields
      */
      if (formData.type != VendorType.PRODUCER) {
        formData.cpc_input = undefined;
        formData.cpc_expr = undefined;
      }


      const newVendor = {
        business_name: formData.business_name,
        current_cpc: formData.cpc_input,
        cpc_expr: formData.cpc_expr,
        type: formData.type,
        receipt_email: formData.receipt_email,
        producer_names: formData.producer_names,
      };

      callEndpoint({
        endpointCall: createVendorEndpoint(newVendor),
        onSuccess: (data) => {
          DisplaySuccessAlert("Vendor Created");
          onVendorCreated?.(data.id, newVendor);

        },
        onError: (error) => DisplayErrorAlert("Could not create vendor", "Network Error", error),
      });
    }

    return (
      <>
        <MSMForm
          schema={CreateVendorFormSchema}
          onSubmit={createVendor}
          ref={formRef}
          hideSubmit>
          <MSMFlexGrid maxColumns={2} className='mb-4'>

            {/* BUSINESS NAME */}
            <MSMFormField name="business_name" label="Business Name">
              {({ field }) =>
                <MSMTextInput
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                />
              }
            </MSMFormField>

            {/* VENDOR TYPE */}
            <MSMFormField name="type" label="Vendor Type">
              {({ field }) =>
                <MSMEnumDropdown
                  enumObject={VendorType}
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                    setSelectedVendorType(newValue);
                  }}
                  ref={field.ref}
                />
              }
            </MSMFormField>

            {/* Contact */}
            <MSMFormField name="contact_number" label="Contact Number">
              {({ field }) =>
                <MSMTextInput
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                />
              }
            </MSMFormField>

            {/* Receipt Email */}
            <MSMFormField name="receipt_email" label="Receipt Email">
              {({ field }) =>
                <MSMTextInput
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                />
              }
            </MSMFormField>

            {/* CURRENT CPC */}
            {isProducer(selectedVendorType) && (
              <MSMFormField name="cpc_input" label="CPC Number">
                {({ field }) =>
                  <MSMTextInput
                    value={field.value}
                    onChange={field.onChange}
                    ref={field.ref}

                  />
                }
              </MSMFormField>
            )
            }

            {/* CPC EXPIRATION DATE */}
            {isProducer(selectedVendorType) && (
              <MSMFormField name="cpc_expr" label="CPC Expiration Date">
                {({ field }) => (
                  <div className="flex justify-center">
                    <MSMDatePicker
                      value={field.value}
                      onChange={field.onChange}
                      ref={field.ref}
                    />
                  </div>
                )}
              </MSMFormField>
            )
            }
          </MSMFlexGrid>

          <MSMFormField name="producer_names" label="Producer Names">
            {({ field }) => (
              <MSMTextInput
                value={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            )}
          </MSMFormField>
        </MSMForm >
      </>
    );
  });

CreateVendorForm.displayName = "CreateVendorForm";

export default CreateVendorForm;
