import { z } from "zod";
import { VendorType } from "./Enums";

// CheckoutOptions
export const CheckoutOptionsSchema = z.object({
  market: z
    .tuple([z.string(), z.number()])
    .refine(([name, id]) => name.length > 0 && id > 0, {
      message: "Market must be selected",
    }),
  date: z
    .date()
    .refine((val) => val !== null, { message: "Date is required" })
    .refine((val) => val && val < new Date(), { message: "Date must be before today" }),
});

// CreateVendorForm
export const CreateVendorFormSchema = z.object({
    "business_name": z.string()
      .min(1, "Business Name is required")
      .max(255, "Business Name must be less than 255 characters"),
    "type": z.nativeEnum(VendorType, {
      errorMap: () => ({ message: "Vendor Type is required" }),
    }),
    "cpc_input": z.string()
      .regex(/^[0-9-]*$/, "Input must only contain numbers and hyphens")
      .optional(),
    "cpc_expr": z.date()
      .optional(),
    "contact_number": z.string()
      .regex(/^[0-9-]*$/, "Input must only contain numbers and hyphens")
      .optional(),
    "receipt_email": z.string()
      .email()
      .refine((val) => val !== null, {
        message: "Email for reciepts is required",
      }),
    "producer_names": z.string()
      .regex(
        /^([a-zA-Z]+(?: [a-zA-Z]+)?)(, [a-zA-Z]+(?: [a-zA-Z]+)?)*$/,
        "Must be a comma-separated list of names"
      )
  });

// ProducerContactForm
export const ProducerContactSchema = z.object({
    "first_name": z.string()
      .min(1, "First Name is required.")
      .max(50, "First Name must not exceed 50 characters."),
    "last_name": z.string()
      .min(1, "Last Name is required.")
      .max(50, "Last Name must not exceed 50 characters."),
    "email": z.string()
      .email("Must be a valid email address.")
      .min(1, "Email is required."),
  });

  // Login
export const LoginFormSchema = z.object({
    "username": z.string()
        .min(1, "Username is required.")
        .max(50, "Username must not exceed 50 characters."),
    "password": z.string()
        .min(1, "Password is required.")
        .max(50, "Password must not exceed 50 characters.")
});