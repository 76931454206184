import { MarketToken, Vendor, MarketFee } from "@typings/DataModels/Common";
import { CPCStatus, FeeType, TokenType } from "@typings/Enums";
import { calculateCPCStatus, displayPercentage, roundMoney, toReadableString } from "@utilities";
import { TokenFieldModel } from "./Checkout";


// Create TokenFieldModel helper
export const createTokenFieldModel = (quantity: number, token: MarketToken) => ({
    quantity,
    token,
});

// Generate CPC Banner text
export const generateCPCBannerText = (
    selectedVendor: Vendor | undefined
): { status: CPCStatus; bannerText: string } => {

    if (selectedVendor) {

        //If the vendor doesn't have a CPC Number
        if (!selectedVendor.current_cpc) {
            return { status: CPCStatus.NONE, bannerText: "No CPC found for this vendor." }
        }

        //If the vendor has a CPC but no expriation date
        if (!selectedVendor.cpc_expr) {
            return { status: CPCStatus.NONE, bannerText: "No CPC date found for this vendor." };
        }

        //Otherwise we can do CPC calculations.
        const { status, daysLeft } = calculateCPCStatus(selectedVendor.cpc_expr);
        const bannerText = `Expr Date: ${selectedVendor.cpc_expr}; due ${status !== CPCStatus.PAST_DUE ? "in" : ""
            } ${Math.abs(daysLeft)} days ${status === CPCStatus.PAST_DUE ? "ago" : ""}`;
        return { status, bannerText };
    }

    return { status: CPCStatus.UP_TO_DATE, bannerText: "No vendor selected, don't display nothing." }

};


// Generate the string explaining the market fee calculation
export const getMarketFeeCalculationString = (
    selectedMarketFee: MarketFee | null,
    fees: MarketFee[],
    selectedVendor: Vendor | undefined,
    govFee: number
): string => {

    let vendorFeeText: string;
    let govFeeText = govFee ? ` + $${roundMoney(govFee)} Gov. fee` : "";

    if (!fees.length) {
        return "No vendor fees for this market" + govFeeText;
    }

    if (!selectedMarketFee) {
        return selectedVendor
            ? `No fee found for ${toReadableString(selectedVendor.type)}` + govFeeText
            : "No fees applicable." + govFeeText;
    }

    const percentFeeDisplay = displayPercentage(selectedMarketFee.percent * 100)

    switch (selectedMarketFee.fee_type) {
        case FeeType.PERCENT_GROSS:
            vendorFeeText = `${percentFeeDisplay}% of Gross`;
            break;

        case FeeType.FLAT_FEE:
            vendorFeeText = `$${selectedMarketFee.flat}`;
            break;

        case FeeType.FLAT_PERCENT_COMBO:
            vendorFeeText = `$${selectedMarketFee.flat} + ${percentFeeDisplay}% of Gross`;
            break;

        case FeeType.MAX_OF_EITHER:
            vendorFeeText = `Max of $${selectedMarketFee.flat} or ${percentFeeDisplay}% of Gross`;
            break;

        default:
            return "Unknown fee type";
    }

    return vendorFeeText + govFeeText
};

// Assembles tokens for transaction submission
export const GenerateTokensForSubmission = (Tokens: TokenFieldModel[]) => {
    return Tokens.map((tokenField) => ({
        market_token_id: tokenField?.token.id ?? 0,
        count: tokenField?.quantity,
        token_type: tokenField?.token.type,
        per_dollar_value: tokenField.token.per_dollar_value
    }));
};

// Helper to get token label
export const getTokenLabel = (token: MarketToken): string => {
    const readableType = TokenType.toString(token.type);
    const valueSuffix =
        token.per_dollar_value !== 1 ? ` - $${token.per_dollar_value}` : "";
    return `${readableType}${valueSuffix}`;
};
